import React, { useState } from "react"
import PropTypes from "prop-types"
import { Transition } from "@headlessui/react"

export default function Alert({ text }) {
  const [show, setShow] = useState(true)
  return (
    <Transition
      show={show}
      enter="transition ease-out duration-100 transform"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="transition ease-in duration-75 transform"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-100"
    >
      <div className="w-full p-2">
        <div className="bg-green-100 rounded-lg mx-auto py-3 px-3 sm:px-3 lg:px-4">
          <div className="flex space-x-12 items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-12 md:w-8 lg:w-5 fill-current text-green-400"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              />
            </svg>
            <p className="text-green-600 mb-1">{text}</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-12 md:w-8 lg:w-5 fill-current text-green-500 cursor-pointer"
              data-testid="closeAlert"
              onClick={() => setShow(false)}
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        </div>
      </div>
    </Transition>
  )
}

Alert.propTypes = {
  text: PropTypes.string,
}
