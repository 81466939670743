import React, { useState } from "react"
import { useForm } from "react-hook-form"
import Alert from "./Alert"
import Heading from "../components/Heading"

const encode = data => {
  console.log(data)
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default function ContactForm() {
  const [processingForm, setProcessingForm] = useState(false)
  const [success, setSuccess] = useState(false)
  const { register, handleSubmit, reset, errors } = useForm()
  const onSubmit = async data => {
    if (data.name === "" || data.email === "" || data.message === "") {
      setProcessingForm(false)
      return null
    }
    setProcessingForm(true)
    const formData = await fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...data }),
    })
    setProcessingForm(false)
    setSuccess(true)
    reset()
  }
  return (
    <section className="text-gray-900 body-font relative">
      <div className="container px-5 pb-24 mx-auto">
        <div className="flex flex-col text-center w-full mb-12">
          <Heading>Contact Hallowed Hollows</Heading>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
            We’re here to help and answer any question you might have.
            <br />
            We look forward to hearing from you
            <br />
            😀
          </p>
        </div>
        <div className="lg:w-1/2 md:w-2/3 mx-auto">
          <div className="flex flex-wrap -m-2">
            <form
              className="flex flex-wrap"
              name="contact"
              method="post"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="p-2 w-1/2">
                <input type="hidden" name="form-name" value="contact" />
                <input
                  className="w-full bg-gray-100 rounded border border-gray-400 focus:outline-none focus:border-olive-500 text-base px-4 py-2"
                  placeholder="Name"
                  type="text"
                  name="name"
                  ref={register({ required: true })}
                />
                {errors.name && (
                  <p className="text-red-500 transition duration-150 ease-in-out">
                    Name is required!
                  </p>
                )}
              </div>
              <div className="p-2 w-1/2">
                <input
                  className="w-full bg-gray-100 rounded border border-gray-400 focus:outline-none focus:border-olive-500 text-base px-4 py-2"
                  placeholder="Email"
                  type="email"
                  name="email"
                  ref={register({ required: true })}
                />
                {errors.email && (
                  <p className="text-red-500 transition duration-150 ease-in-out">
                    Email is required!
                  </p>
                )}
              </div>
              <div className="p-2 w-full">
                <textarea
                  className="w-full bg-gray-100 rounded border border-gray-400 focus:outline-none h-48 focus:border-olive-500 text-base px-4 py-2 resize-none block"
                  placeholder="Message"
                  name="message"
                  ref={register({ required: true })}
                ></textarea>
                {errors.message && (
                  <p className="text-red-500 transition duration-150 ease-in-out">
                    Message is required!
                  </p>
                )}
              </div>
              <div className="p-2 w-full">
                {processingForm ? (
                  <button
                    type="button"
                    className="flex mx-auto w-full justify-center text-white bg-olive-400 border-0 py-2 px-8 focus:outline-none hover:bg-olive-500 rounded text-lg items-center cursor-not-allowed"
                    disabled
                  >
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    Processing
                  </button>
                ) : (
                  <button className="flex mx-auto w-full justify-center text-white bg-olive-500 border-0 py-2 px-8 focus:outline-none hover:bg-olive-600 rounded text-lg transition duration-150 ease-in-out">
                    Send Message
                  </button>
                )}
              </div>
            </form>
            {success && (
              <Alert text="Thanks for contacting us. We look forward to working with you." />
            )}
            <div className="flex flex-col p-2 w-full pt-8 mt-8  border-t border-gray-200 text-center space-y-3">
              <a
                href="mailto:hallowedhollows@gmail.com?subject=Contact Mail from hallowedhollows.net"
                className="text-olive-500"
              >
                hallowedhollows@gmail.com
              </a>
              <a href="tel:334-703-5876" className="text-olive-500">
                (334)703-5876
              </a>
              <p className="leading-normal mb-5">1825 21st Ave.</p>
              <p className="leading-normal">Calera, AL 35040</p>
              <span className="flex self-center">
                <a
                  className="text-gray-500 hover:text-blue-800 transition duration-150 ease-in-out"
                  href="https://www.facebook.com/hallowedhollows"
                >
                  <svg
                    fill="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="w-6 h-6"
                    viewBox="0 0 24 24"
                  >
                    <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                  </svg>
                </a>
                <a
                  className="ml-4 text-gray-500 hover:text-pink-600 transition duration-150 ease-in-out"
                  href="https://www.instagram.com/hallowedhollows/"
                >
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="w-6 h-6"
                    viewBox="0 0 24 24"
                  >
                    <rect
                      width="20"
                      height="20"
                      x="2"
                      y="2"
                      rx="5"
                      ry="5"
                    ></rect>
                    <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                  </svg>
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
