import React from "react"
import Layout from "../components/Layout"
import ContactForm from "../components/ContactForm"
import SEO from "../components/SEO"

export default function Contact() {
  return (
    <Layout>
      <SEO
        meta={{
          title: "Hallowed Hollows | Contact Us",
          description: "Hallowed Hollows Contact Page",
        }}
      />
      <ContactForm />
    </Layout>
  )
}
